import { getItem } from '@/utils/storage'
import i18n from '@/i18n'
import store from '@/store'
import { mapList } from '@/config/optionActionMap.config'
import {
  useCommonParam,
  useCommonParamNoAsync
} from '@/hooks/use-common-params.js'

const getParamsLabel = (label, isNeedInternational = false) => {
  return isNeedInternational ? i18n.global.t(label) : label
}

/**
 * @item string 必传，当前的参数字段
 * @type string 必传，类型数据，值为keyType、atmType等类型
 * @isNeedPreFix boolean 表示显示时是否需要前缀
 * @isReturnSelf boolean 表示当查找不到list对应数据时，需返回空还是返回自身
 * @return string 用于返回当前项的value值对应数组里的label值
 */
export const getCommonParamsItemLabel = (
  item,
  type,
  {
    isNeedPreFix = true,
    isNeedInternational = false,
    isReturnSelf = true,
    compareParam = 'value',
    labelParams1 = 'value',
    labelParams2 = 'key'
  } = {}
) => {
  // const cachedOptions = store.state.globalParams.cacheObject
  // const key = 'common.param.' + type
  // if (cachedOptions[key]) {
  //   console.log(cachedOptions)
  //   return cachedOptions[key]
  // } else {
  const list = useCommonParamNoAsync(type)
  for (const i in list) {
    if (item === list[i][compareParam]) {
      if (isNeedPreFix) {
        return (
          list[i][labelParams1] +
          ' - ' +
          getParamsLabel(list[i][labelParams2], isNeedInternational)
        )
      } else {
        return getParamsLabel(list[i][labelParams2], isNeedInternational)
      }
    }
  }
  // store.commit('globalParams/setCacheObject', { key, options: list })
  // }
  // 当查找不到时 判断条件返回自身或者是空字符串
  if (isReturnSelf) {
    return item
  } else {
    return ''
  }
}

/**
 * @type string 必传，类型数据，值为keyType、atmType等类型
 * @isNeedPreFix boolean 表示显示时是否需要前缀
 * @return list 返回options数组
 */
export const getCommonParamsOptions = async (
  type,
  {
    isNeedPreFix = true,
    isNeedInternational = false,
    valueParams = 'value',
    labelParams1 = 'value',
    labelParams2 = 'key'
  } = {}
) => {
  let list = await useCommonParam(type)
  if (!list || !list.length) {
    return []
  }
  list = list.map((item) => {
    if (isNeedPreFix) {
      return {
        label:
          item[labelParams1] +
          ' - ' +
          getParamsLabel(item[labelParams2], isNeedInternational),
        value: item[valueParams]
      }
    } else {
      return {
        label: getParamsLabel(item[labelParams2], isNeedInternational),
        value: item[valueParams]
      }
    }
  })
  return list
}

/**
 * @type string 必传，类型数据，值为branchInfoList、eventFeeList等类型
 * @isNeedPreFix boolean 表示显示时是否需要前缀
 * @valueParams string 用于显示list相关的value参数名称
 * @labelParams1 string 用于显示list相关的label参数1
 * @labelParams2 string 用于显示list相关的label参数2
 * @return string 用于返回当前项的value值对应数组里的label值
 */
// 当isNeedPreFix，valueParams，labelParams1，labelParams2不是默认值的情况，再动态传参修改，否则不需要传
export const getGlobalParamsOptionsAsync = async (
  type,
  {
    isNeedPreFix = true,
    valueParams = 'id',
    labelParams1 = 'name',
    labelParams2 = 'name'
  } = {}
) => {
  // if not cached
  const cachedOptions = store.state.globalParams.cacheObject
  if (cachedOptions[type]) {
    return cachedOptions[type]
  } else {
    // Fetch data if not cached
    let list = []
    list = await store.dispatch(mapList[type])
    // Transform the data if needed
    list = list?.map((item) => {
      if (isNeedPreFix) {
        return {
          label: item[labelParams1] + ' - ' + item[labelParams2],
          value: item[valueParams]
        }
      } else {
        return { label: item[labelParams2], value: item[valueParams] }
      }
    })
    // Store the data in Vuex cache
    store.commit('globalParams/setCacheObject', { type, options: list })
    return list
  }
  // return []
}

export const getGlobalParamsOptions = (
  type,
  {
    isNeedPreFix = true,
    valueParams = 'id',
    labelParams1 = 'name',
    labelParams2 = 'name'
  } = {}
) => {
  let list = getItem(type)
  if (!list || !list.length) {
    return []
  }
  list = list.map((item) => {
    if (isNeedPreFix) {
      return {
        label: item[labelParams1] + ' - ' + item[labelParams2],
        value: item[valueParams]
      }
    } else {
      return { label: item[labelParams2], value: item[valueParams] }
    }
  })

  return list
}
/**
 * @item string 必传，当前的参数字段
 * @type string 必传，类型数据，值为branchInfoList、eventFeeList等类型
 * @isNeedPreFix boolean 表示显示时是否需要前缀
 * @compareParam string 用于list比对的参数字段，默认比对code参数，也可以动态传入id等
 * @labelParams1 string 用于显示list相关的参数1
 * @labelParams2 string 用于显示list相关的参数2
 * @isReturnSelf boolean 表示当查找不到list对应数据时，需返回空还是返回自身
 * @return string 返回options数组
 */
// 当isNeedPreFix，compareParam，labelParams1，labelParams2不是默认值的情况，再动态传参修改，否则不需要传
export const getGlobalParamsListItemLabel = (
  item,
  type,
  {
    isNeedPreFix = true,
    compareParam = 'id',
    labelParams1 = 'name',
    labelParams2 = 'name'
    // isReturnSelf = true
  } = {}
) => {
  const list = getItem(type)
  // 查到list是否有当前参数对应的value值，有的话则返回对应的name
  if (!item) {
    return ''
  }
  for (const i in list) {
    if (item === list[i][compareParam]) {
      if (isNeedPreFix) {
        return list[i][labelParams1] + ' - ' + list[i][labelParams2]
      } else {
        return list[i][labelParams2]
      }
    }
  }
  // 当查找不到时 跟进条件返回自身或者是空字符串
  // if (isReturnSelf) {
  //   return item
  // } else {
  //   return ''
  // }
  return ''
}

/**
 * @item string 必传，当前的参数字段
 * @options string 必传，options列表,为const文件对应的options数组
 * @isNeedPreFix boolean 表示显示时是否需要前缀
 * @return string 用于返回当前项的value值对应数组里的label值
 */
export const getOptionsItemLabel = (item, options, isNeedPreFix = false) => {
  for (const i in options) {
    if (item === options[i].value) {
      if (isNeedPreFix) {
        return options[i].value + ' - ' + options[i].label
      } else {
        return options[i].label
      }
    }
  }
}

// 获取基础参数 无需设置label以及Value的options
export const getParamsOptionsAsync = async (type) => {
  // if not cached
  const cachedOptions = store.state.globalParams.cacheObject
  if (cachedOptions[type]) {
    console.log(cachedOptions[type])
    return cachedOptions[type]
  } else {
    // Fetch data if not cached
    let list = []
    list = await store.dispatch(mapList[type])
    // Store the data in Vuex cache
    store.commit('globalParams/setCacheObject', { type, options: list })
    return list
  }
  // return []
}

// 获取基础参数 无需设置label以及Value的options及直接读取缓存不需要调用接口
export const getParamsOptions = (type) => {
  const list = getItem(type)
  if (!list || !list.length) {
    return []
  }
  return list
}
