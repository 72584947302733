<template>
  <div class="base-form">
    <ws-form ref="formComRef" v-bind="baseFormConfig" v-model="formData">
      <template #header>
        <h3 class="header" style="font-weight: bold; padding-left: 10px">
          {{ pageTitle }}
        </h3>
        <hr
          style="
            margin-top: 20px;
            margin-bottom: 10px;
            width: 98%;
            margin-left: 1%;
            color: #e9e9eb;
            background-color: #e9e9eb;
            height: 1px;
            border: none;
          "
        />
        <div class="alert-content">
          <slot name="alertContent" />
        </div>
      </template>
      <template
        v-for="item in formItems"
        :key="item.field"
        #[item.slotName]="scope"
      >
        <template v-if="item.slotName">
          <slot :name="item.slotName" :form="scope.form"></slot>
        </template>
      </template>
      <template #form>
        <slot name="customForm" />
      </template>
      <template #footer>
        <div class="handle-btns">
          <slot name="otherBtn"></slot>
          <el-button
            v-if="!isView"
            type="primary"
            icon="el-icon-check"
            @click="handleSubmitClick"
            plain
            size="small"
            >{{ $t('general.submit') }}</el-button
          >
          <el-button
            v-if="!isView"
            type="warning"
            icon="el-icon-refresh"
            @click="handleResetClick"
            plain
            size="small"
            >{{ $t('general.reset') }}</el-button
          >
          <slot name="otherFooter"></slot>

          <el-button
            v-if="isShowBackBtn"
            icon="el-icon-back"
            @click="handleBackClick"
            size="small"
            plain
            >{{ $t('general.back') }}</el-button
          >
        </div>
      </template>
    </ws-form>
  </div>
</template>

<script setup>
import WsForm from '@/base-ui/form/src/form.vue'
import {
  ref,
  defineProps,
  defineExpose,
  defineEmits,
  computed,
  watch,
  onMounted
} from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
const props = defineProps({
  baseFormConfig: {
    type: Object,
    required: true
  },
  pageTitle: {
    type: String,
    required: true
  },
  pageInfo: {
    type: Object,
    default: () => {}
  },
  isResetFields: {
    type: Boolean,
    default: true
  },
  pageType: {
    type: String,
    validator: (value) => ['view', 'edit', 'add'].includes(value)
  },
  isShowBackBtn: {
    type: Boolean,
    default: true
  }
})
const isView = computed(() => props.pageType === 'view')
// 筛选当前页面的类型

const emit = defineEmits(['handleNewClick', 'resetBtnClick'])
const formItems = ref(props.baseFormConfig?.formItems || [])
const formOriginData = {}

const resolveGlobalParamsOptions = async (formItems) => {
  for (const item of formItems) {
    if (item.isResolveGlobalParams && typeof item.handler === 'function') {
      try {
        const result = await item.handler()
        item.options = result
      } catch (error) {
        console.error(`Error resolving ${item.field} options:`, error)
        item.options = []
      }
    }
  }
  if (pageInfoLength.value) {
    // 这里等options数据全部加载完毕后 formData要重新赋值，否则会导致option的value显示的是选中value而不是对应label
    formData.value = {}
    formData.value = handleTransFormData(props.pageInfo)
  }
}

onMounted(async () => {
  await resolveGlobalParamsOptions(formItems.value)
})

// 获取formItem中的每一项 并将其域值转为''
for (const item of formItems.value) {
  if (item?.otherOptions?.multiple) {
    formOriginData[item.field] = []
  } else {
    // 注意为 0 的情况
    formOriginData[item.field] =
      item.initialValue || item.initialValue === 0 ? item.initialValue : ''
  }
}

const formData = ref(formOriginData)

const formComRef = ref()

// 处理数据回显问题
const pageInfoLength = computed(() => {
  let length = 0
  if (props.pageInfo !== null && props.pageInfo !== undefined) {
    length = Object.keys(props.pageInfo).length
  }
  return length
})

const handleTransFormData = (form = {}) => {
  const formArr = props.baseFormConfig.formItems
  if (formArr && formArr.length) {
    formArr.map((item) => {
      // 当后端返回的的参数有值或者为0时才赋值，否则使用formData的默认值
      if (form[item.field] || form[item.field] === 0) {
        formData.value[item.field] = form[item.field]
      } else {
        formData.value[item.field] = formOriginData[item.field]
      }
    })
  }
  return formData.value
}

watch(
  pageInfoLength,
  (newVal, oldVal) => {
    if (newVal > 0 || oldVal > 0) {
      handleTransFormData(props.pageInfo)
    }
  },
  {
    immediate: true
  }
)
// 处理Edit Page的reset操作 回复到初始化数据
const handleResetInitial = () => {
  let length = 0
  if (props.pageInfo !== null && props.pageInfo !== undefined) {
    length = Object.keys(props.pageInfo).length
  }
  const object = {}

  if (length > 0) {
    const keys = Object.keys(formData.value)
    for (const item of keys) {
      object[item] = props.pageInfo[item]
    }
    formData.value = object
  }
}

// 处理提交表单校验
const handleSubmitClick = async () => {
  const res = await formComRef.value.handleValidate()
  if (res) {
    // 校验成功 做数据服务端的提交
    emit('handleNewClick', formData.value)
  } else {
    ElMessage.error('Please check the required field.')
  }
}

//  处理重置校验 以及表单内容清空
const handleResetClick = () => {
  console.log(formOriginData)
  console.log(props.isResetFields)
  if (props.isResetFields) {
    formData.value = formOriginData
    formComRef.value.handleResetValidate(true)
  } else {
    handleResetInitial()
    // 添加重置成初始数据后 去除校验
    formComRef.value.handleResetValidate()
  }
  emit('resetBtnClick')
}

const router = useRouter()
const handleBackClick = () => {
  router.go(-1)
}

const handleValidate = () => {
  const res = formComRef.value.handleValidate()
  return res
}

// 只对表单的某个属性进行校验
const handleFormValidateField = (fieldName) => {
  formComRef.value.handleValidateField(fieldName)
}

// 移除表单的某个属性的校验
const handleRemoveFieldValidate = (fieldName) => {
  formComRef.value.handleItemValidate(fieldName)
}

defineExpose({
  handleBackClick,
  handleValidate,
  handleResetClick,
  handleFormValidateField,
  handleRemoveFieldValidate,
  formData
})
</script>

<style scoped>
.handle-btns {
  text-align: center;
  padding: 0 50px 20px 0;
}
.alert-content {
  margin: 15px;
}
</style>
