// 预定义按钮权限数据 application 模块
// const btnPermissionList = [
// 'application.applications.edit',
//   'application.applications.create',
//   'application.applications.delete',
//   'application.applications.view'
// ]
import { getItem } from '@/utils/storage'
import { BTN_PERMISSION_LIST } from '@/utils/constant/index'

const whiteList = [
  // 三级菜单按钮权限不控制 默认都有权限
  // 'file.applicationFile.create',
  // 'file.applicationFile.delete',
  // 'file.otaFile.create',
  // 'file.otaFile.delete',
  // 'system.dictDetail.create',
  // 'system.dictDetail.edit',
  // 'system.dictDetail.delete',
  // 'system.dictDetail.switch'
  // 分割线
  // 'system.sendMessage.delete'
]
const isNoNeedPermission = (btn) => whiteList.includes(btn)

export const usePermission = (moduleName, pageName, operation) => {
  // 1、store里获取btnPermissionList的state >> 当前后台接口有问题 直接返回
  const btnPermissionList = getItem(BTN_PERMISSION_LIST) || []
  // 2、将当前路径进行拼接处理
  const verifyPermission = `${moduleName}.${pageName}.${operation}`
  // 3、 当在白名单中的按钮，则不需要判断权限
  if (isNoNeedPermission(verifyPermission)) {
    return true
  }
  // 3、在btnPermissionList中查找并返回Boolean值
  return !!btnPermissionList.find((item) => item === verifyPermission)
}
