export const mapList = {
  terminalModel: 'globalParams/getTerminalModelList',
  terminalList: 'globalParams/getTerminalList',
  unselectTerminalList: 'globalParams/getUnselectTerminalList',
  tenantList: 'globalParams/getTenantList',
  terminalParamGroup: 'globalParams/getTerminalParamGroupList',
  paramTemplate: 'globalParams/getParamTemplateList',
  roleList: 'user/getRoleList',
  groupList: 'user/getGroupList',
  merchantList: 'user/getMerchantList',
  userList: 'globalParams/getUserList',
  geoFenceList: 'globalParams/getGeoFenceList',
  geoFenceGroupList: 'globalParams/getGeoFenceGroupList',
  moduleList: 'globalParams/getModuleList',
  operationTypeList: 'globalParams/getOperationTypeList',

  // todo 以上是目前系统使用到的options 以下是旧系统的option 如新系统有使用到下面的option 请将其移至上方
  applicationTypeList: 'application/getApplicationList',
  customerList: 'user/getCustomerList',
  modelsList: 'terminal/getModelList',
  otaStatusList: 'ota/getOtaOptionList',
  customerStatusList: 'tenant/getCustomerStatusList',
  customerTypeList: 'tenant/getCustomerTypeList',
  merchantStatusList: 'tenant/getMerchantStatusList',
  merchantTypeList: 'tenant/getMerchantTypeList',
  terminalStatusList: 'terminal/getTerminalOptionList',
  traceTypeList: 'terminal/getTraceOptionList',
  paramValueTypeList: 'param/getParamValueTypeList',
  paramStatusList: 'param/getParamStatusList'
}
