<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      :baseFormConfig="baseFormConfigRef"
      :pageTitle="pageTitle"
      :pageInfo="pageInfo"
      pageType="view"
    >
      <template #otherBtn>
        <el-button
          class="opt-btn"
          v-if="isLogs"
          icon="el-icon-tickets"
          size="small"
          @click="handleLogsData"
          >{{ $t('general.logs') }}</el-button
        ></template
      ></page-form
    >
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseFormConfig } from './config/user.view.js'
import { usePermission } from '@/hooks/use-permission'

import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { findCurrentSelectedTreeNode } from '@/utils/util.js'

import { ref, onMounted } from 'vue'
import i18n from '@/i18n'
import { getParamsOptions } from '@/utils/common'
import { getItem } from '@/utils/storage'

// 页面标题
const pageTitle = ref('')
const { t } = i18n.global

pageTitle.value = t('general.router-user-view')

const routes = useRoute()
const router = useRouter()

const name = routes.params.name
const id = routes.params.id

const store = useStore()
const pageInfo = ref({})
const baseFormConfigRef = ref(baseFormConfig)

const isLogs = usePermission('user', 'users', 'logs')

const handleLogsData = () => {
  router.push(`/users/logs/${name}`)
}

const tenantList = getParamsOptions('tenantList')

const isShowTenant = (roleIds) => {
  const tenantItem = baseFormConfigRef.value.formItems.find(
    (item) => item.field === 'tenantId'
  )
  const roleLists = getItem('roleList') || []
  const roleType = []
  if (roleIds && roleIds.length) {
    roleIds.map((id) => {
      roleLists.map((item) => {
        if (item.value === id) {
          roleType.push(item.label)
        }
      })
    })
    tenantItem.isShow = !roleType.some((item) => item === 0)
  }
}

onMounted(async () => {
  getCurrentPageInfo()
})

const getCurrentPageInfo = async () => {
  // 获取当前页面的初始化值
  const userInfo = await store.dispatch('user/getUserDetailById', { id })
  // let rolesList = []
  if (JSON.stringify(userInfo) !== '{}') {
    userInfo.tenantId =
      findCurrentSelectedTreeNode(tenantList, userInfo.tenantId, 'id') || ''
    userInfo.roleId = userInfo.roleId ? userInfo.roleId.split(',') : []
    isShowTenant(userInfo.roleId)

    // rolesList = userInfo.roles.map((item) => {
    //   return item.id
    // })
    // userInfo.roles = Array.from(new Set(rolesList))
  }
  pageInfo.value = userInfo
}
</script>
