import {
  getGlobalParamsOptionsAsync
  // getParamsOptionsAsync
} from '@/utils/common'

export const baseFormConfig = {
  labelWidth: '180px',
  itemStyle: {
    padding: '5px 20px'
  },
  formItems: [
    {
      field: 'userName',
      type: 'input',
      label: 'user.user-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'firstName',
      type: 'input',
      label: 'user.first-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'middleName',
      type: 'input',
      label: 'user.middle-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'lastName',
      type: 'input',
      label: 'user.last-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'email',
      type: 'input',
      label: 'user.email',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'mobile',
      type: 'input',
      label: 'user.phone',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'expiryDays',
      type: 'input',
      label: 'user.password-expiry',
      initialValue: '90',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'address',
      type: 'input',
      label: 'user.address',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'roleId',
      type: 'select',
      label: 'common.app-user-role',
      options: [],
      isLinkageOptions: true,
      isResolveGlobalParams: true,
      handler: () =>
        getGlobalParamsOptionsAsync('roleList', {
          isNeedPreFix: false
        }),
      otherOptions: {
        disabled: true,
        multiple: true
      }
    },
    {
      field: 'tenantId',
      type: 'input',
      label: 'file.tenant',
      isShow: false,
      // isResolveGlobalParams: true,
      otherOptions: {
        disabled: true
        // props: {
        //   multiple: true
        // }
      }
      // options: [],
      // handler: () =>
      //   getParamsOptionsAsync('tenantList', {
      //     isNeedPreFix: false
      //   })
    }
  ]
}
